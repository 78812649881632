var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-spin", { attrs: { spinning: _vm.processing } }, [
    _c("h1", [_vm._v("En cours...")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }