<template>
  <a-spin :spinning="processing">
    <h1>En cours...</h1>
  </a-spin>
</template>
<script>
import Axios from "axios";
import auth from "@/services/auth";

export default {
  created() {
    Axios.get(`${auth.getBaseUrl()}/remplir_etat_paiement`)
      .then(function(response) {
        alert("Success");
      })
      .catch(() => alert("Error"))
      .finally(() => (this.processing = false));
  },
  data() {
    return {
      processing: true,
    };
  },
};
</script>
